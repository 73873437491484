import axios from 'axios';


const instance = axios.create({
    baseURL: `${process.env.REACT_APP_API_PATH}${process.env.REACT_APP_API_PREFIX}`
});


// Add a request interceptor
axios.interceptors.request.use(function (config) {
    // Do something before request is sent
    console.log('request');
    return config;
}, function (error) {
    // Do something with request error
    return Promise.reject(error);
});

// Add a response interceptor
axios.interceptors.response.use(function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    console.log('response');
    return response;
}, function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject(error);
});

export default instance;


