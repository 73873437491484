import { commonTheme } from './common';

const theme = {
    colors: {
        leftMenu: {
            background: 'green',
            border: 'blue'
        }
    },
    ...commonTheme
};

export default theme;

