import { httpRequestTypes } from '../../../../redux/middlewares';

const SiteRedux = {
    UI: {
        TOGGLE_MOBILE_MENU: "TOGGLE_MOBILE_MENU",
        CHANGE_GALLERY_ORDER: 'CHANGE_GALLERY_ORDER',
        CHANGE_TABS_ORDER: 'CHANGE_TABS_ORDER'
    },
    HTTP: {
        GET_PAGE_DATA: httpRequestTypes("GET_PAGE_DATA"),
        GET_RESUME_PAGE: httpRequestTypes("GET_RESUME_PAGE"),
        GET_ABOUT_ME_PAGE: httpRequestTypes("GET_ABOUT_ME_PAGE"),
        CONTACT_US_SEND: httpRequestTypes("CONTACT_US_SEND"),
        GET_PROTFOLIO: httpRequestTypes("GET_PROTFOLIO")
    }
};

export default SiteRedux;