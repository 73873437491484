import Types from '../types';
import { FormStatus } from '../../constants';


const defaultState = {
    isMobileMenuOpen: false,
    page: {
        isLoading: false,
        data: null
    },
    resume: null,
    education: null,
    techs: null,
    aboutme: null,
    contactUs: {
        status: FormStatus.READY
    },
    protfolio: null,
    protfolioSelectedTab: 0
    // protfolio: [
    //     {
    //         id: 1,
    //         title: 'GUARDED ACCESS',
    //         description: "bla bla bla bla",
    //         gallery: [
    //             {
    //                 order: 1,
    //                 path: 'https://picsum.photos/id/237/200/300'
    //             },
    //             {
    //                 order: 2,
    //                 path: 'https://picsum.photos/200/300?grayscale'
    //             },
    //             {
    //                 order: 3,
    //                 path: 'https://picsum.photos/id/102/200/300'
    //             },
    //             {
    //                 order: 4,
    //                 path: 'https://picsum.photos/id/110/200/300'
    //             }
    //         ]
    //     },
    //     {
    //         id: 2,
    //         title: 'GUARDED ACCESS Advanced',
    //         description: "llll llll llll llll ",
    //         gallery: [
    //             {
    //                 order: 1,
    //                 path: 'https://picsum.photos/id/237/200/300'
    //             },
    //             {
    //                 order: 2,
    //                 path: 'https://picsum.photos/200/300?grayscale'
    //             },
    //             {
    //                 order: 3,
    //                 path: 'https://picsum.photos/id/102/200/300'
    //             },
    //             {
    //                 order: 4,
    //                 path: 'https://picsum.photos/id/110/200/300'
    //             }
    //         ]
    //     }
    // ]
};


export default function reducer(state = defaultState, { type, payload, extraData }) {
    // console.log('type ', type);
    switch (type) {
        case Types.UI.TOGGLE_MOBILE_MENU:
            const { isOpen } = payload
            return {
                ...state,
                isMobileMenuOpen: isOpen === null ? !state.isMobileMenuOpen : isOpen
            };
        case Types.HTTP.GET_PAGE_DATA.FETCH:
        case Types.HTTP.GET_RESUME_PAGE.FETCH:
        case Types.HTTP.GET_PROTFOLIO.FETCH:
        case Types.HTTP.GET_ABOUT_ME_PAGE.FETCH:
            return {
                ...state,
                page: {
                    ...state.page,
                    isLoading: true
                }
            };
        case Types.HTTP.GET_PAGE_DATA.SECCUSS:
            const { pageId } = extraData;
            return {
                ...state,
                page: {
                    data: {
                        ...state.page.data,
                        [pageId]: {
                            description: payload.description,
                            keywords: payload.keywords,
                            pageName: payload.pageName,
                            title: payload.title
                        }
                    },
                    isLoading: false
                }
            };
        case Types.HTTP.GET_PAGE_DATA.ERROR:
            return {
                ...state,
                page: {
                    data: null,
                    isLoading: false
                }
            };
        //
        case Types.HTTP.GET_RESUME_PAGE.SECCUSS:
            return {
                ...state,
                page: {
                    ...state.page,
                    isLoading: false
                },
                resume: payload.resume,
                education: payload.education,
                techs: payload.techs
            };
        case Types.HTTP.CONTACT_US_SEND.SECCUSS:
            return {
                ...state,
                contactUs: {
                    ...state.contactUs,
                    status: FormStatus.COMPLETED
                }
            };
        case Types.HTTP.GET_PROTFOLIO.SECCUSS:
            return {
                ...state,
                page: {
                    ...state.page,
                    isLoading: false
                },
                protfolio: payload.map((prot, i) => ({
                    ...prot,
                    order: (i + 1),
                    selected: 1,
                    gallery: prot.gallery.map((g, i) => ({
                        id: g,
                        order: (i + 1)
                    }))
                }))
            };
        case Types.HTTP.GET_ABOUT_ME_PAGE.SECCUSS:
            return {
                ...state,
                page: {
                    ...state.page,
                    isLoading: false
                },
                aboutme: payload
            };
        //UI
        case Types.HTTP.CONTACT_US_SEND.ERROR:
            return {
                ...state,
                contactUs: {
                    ...state.contactUs,
                    status: FormStatus.FAILED
                }
            };
        case Types.UI.CHANGE_GALLERY_ORDER:
            return {
                ...state,
                protfolio: state.protfolio.map(prot => {
                    if (prot.id === payload.id) {
                        return {
                            ...prot,
                            gallery: prot.gallery.map(item => {
                                const { order } = item;
                                let newOrder;
                                if (payload.type === 'right') {
                                    newOrder = order + 1;
                                    newOrder = newOrder > prot.gallery.length ? 1 : newOrder;
                                }
                                else {
                                    newOrder = order - 1;
                                    newOrder = newOrder === 0 ? prot.gallery.length : newOrder;
                                }
                                return {
                                    ...item,
                                    order: newOrder
                                }
                            })
                        }
                    }
                    return prot;
                })
            };
        case Types.UI.CHANGE_TABS_ORDER:
            return {
                ...state,
                protfolio: state.protfolio.map(prot => {
                    // if (prot.id === payload.id) {
                    // debugger
                    const { order } = prot;
                    let newOrder;
                    if (payload.type === 'right') {
                        newOrder = order + 1;
                        newOrder = newOrder > state.protfolio.length ? 1 : newOrder;
                    }
                    else {
                        newOrder = order - 1;
                        newOrder = newOrder === 0 ? state.protfolio.length : newOrder;
                    }
                    return {
                        ...prot,
                        order: newOrder
                    }
                    // }
                    // return prot;
                })
            };
        default:
            return state;
    }
}