import React, { lazy } from 'react';
//
import store from '../../redux/store';
import reducer from './redux';
//
const Site = lazy(() => import('./site.component'));

const route = {
    routeProps: {
        path: '/',
        render: (routeProps) => {
            store.injectReducer('site', reducer);
            return <Site {...routeProps} />;
        }
    },
    name: 'Site'
};

export default route;