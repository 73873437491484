// import ReduxConstants from '../../redux.constants';

const defaultState = {
};

export default function reducer(state = defaultState, action) {
    switch (action.type) {
        default:
            return state;
    }
}