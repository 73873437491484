const HTTP_ACTION = "HTTP_ACTION";

const httpAction = (action) => {

    const httpActionTemplate = {
        type: "",
        path: null,
        method: "GET",
        data: null,
        headers: [],
        extraData: {}
    };
    
    return {
        HTTP_ACTION: Object.assign({}, httpActionTemplate, action)
    };
}

export const httpRequestTypes = type => ({
    [`FETCH`]: `${type}_FETCH`,
    [`SECCUSS`]: `${type}_SECCUSS`,
    [`ERROR`]: `${type}_ERROR`,
    [`OTHER`]: `${type}_OTHER`
});

export { HTTP_ACTION, httpAction }


