import { HTTP_ACTION } from './'
import axios from '../../../services/axios'

const httpMiddleware = store => next => action => {
    if (action[HTTP_ACTION]) {

        const actionInfo = action[HTTP_ACTION];

        next({
            type: actionInfo.type.FETCH
        });

        axios({
            url: actionInfo.path,
            method: actionInfo.method,
            data: actionInfo.data
        }).then(result => {
            
            next({
                type: actionInfo.type.SECCUSS,
                payload: result.data,
                extraData: actionInfo.extraData
            });

        }, error => {

            next({
                type: actionInfo.type.ERROR,
                payload: error.response
            });
        })

    }
    else {
        next(action);
    }
}

export default httpMiddleware;