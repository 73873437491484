import React, { Suspense, useEffect, useState } from 'react';
import ScrollTrigger from '@terwanerik/scrolltrigger';
//context
import { ScrollAnimationContext } from './context';
//
import moduleRoutes from './modules';
//
import Theme from './theme';
//
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
//redux
import { Provider } from 'react-redux';
import store from './redux/store';
//fonts
import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';
import {
  fal
} from '@fortawesome/pro-light-svg-icons';
library.add(fab);
library.add(fal);

const App = () => {

  // const [selectedTheme, setSelectedTheme] = useState(1);
  const [selectedTheme] = useState(1);
  const [scroll, setScroll] = useState(null);

  useEffect(() => {
    setScroll(
      new ScrollTrigger({
        trigger: {
          once: true,
          toggle: {
            class: {
              in: 'show-animation',
              out: 'hide-animation'
            }
          }
        }
      }))
  }, [])


  return (
    <Provider store={store}>
      <Router>
        <Suspense fallback={<div></div>}>
          <Theme selectedTheme={selectedTheme}>
            <ScrollAnimationContext.Provider value={scroll}>
              <Switch>
                {
                  moduleRoutes.map(module => (
                    <Route path={module.routeProps.path} key={module.name} render={module.routeProps.render} />
                  ))
                }
              </Switch>
            </ScrollAnimationContext.Provider>
          </Theme>
        </Suspense>
      </Router>
    </Provider>
  )
}


export default App;
