import React from 'react';
import { ThemeProvider } from 'styled-components';
import { lightTheme, darkTheme } from './themes';
import GlobalStyle from './global';

const Theme = ({ children, selectedTheme }) => {
    return (
        <>
            <GlobalStyle theme={selectedTheme === 0 ? lightTheme : darkTheme} />
            <ThemeProvider theme={selectedTheme === 0 ? lightTheme : darkTheme}>
                {children}
            </ThemeProvider>
        </>
    )
};

export default Theme;