import { createStore, combineReducers, applyMiddleware } from 'redux';
import reducer from '../reducers';
//middlewares
import thunk from 'redux-thunk';
import { httpMiddleware } from '../middlewares'

// Define the Reducers that will always be present in the application
const staticReducers = {
    commonReducer: reducer
}

// Configure the store
const configureStore = (initialState) => {
    const store = createStore(createReducer(), initialState,
        applyMiddleware(thunk, httpMiddleware));

    // Add a dictionary to keep track of the registered async reducers
    store.asyncReducers = {}

    // Create an inject reducer function
    // This function adds the async reducer, and creates a new combined reducer
    store.injectReducer = (key, asyncReducer) => {
        store.asyncReducers[key] = asyncReducer
        store.replaceReducer(createReducer(store.asyncReducers))
    }

    // Return the modified store
    return store
}

function createReducer(asyncReducers) {
    return combineReducers({
        ...staticReducers,
        ...asyncReducers
    })
};

const store = configureStore({});
export default store;