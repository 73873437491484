import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
    *{
        box-sizing:border-box;        
    }
    body {
        font-family: "Inter",Arial, Helvetica, sans-serif;     
        margin:0;   
        background:${(props) => props.theme.colors.body.background};
    }
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p {
        margin: 0;
        padding: 0;
    }

    h1 {
        font-size: 2rem;
    }

    h2 {
        font-size: 1.25rem;
    }

    h3 {
        font-size: 1rem;
    }

    h4 {
        font-size: 0.75rem;
    }

    p {
        font-size: 1rem;
    }

    a {
        color: inherit;
        text-decoration: none;
    }

    ul,
    ol {        
        margin: 0;
        padding: 0;
    }

    ul{
        list-style-type: none;
    }

    ul li,
    ol li{
        margin: 0;
        padding: 0;
    }
`;

export default GlobalStyle;
