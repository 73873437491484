import { commonTheme } from './common';

const theme = {
    colors: {
        body: {
            background: '#2C2D2F'
        },
        leftMenu: {
            background: '#17181B',
            border: '#202226',
            nameBanner: '#D1A71D',
            links: '#777',
            linksHover: '#fff'
        },
        mobileHeader: {
            background: '#17181B',
            color: '#fff'
        },
        header: {
            color: '#D1A71D'
        },
        progress: {
            background: '#404144'
        },
        tabs: {
            background: '#00A3E1'
        },
        gallery: {
            arrowColor: "#D1A71D",
            arrowBackground: 'rgba(23, 24, 27, .5)'
        }
    },
    ...commonTheme
};

export default theme;

