const commonTheme = {
    fontSizes: {
        small: "1em",
        medium: "2em",
        large: "3em",
    },
    media: {
        small: `(min-width: 576px)`,
        medium: '(min-width: 768px)',
        large: '(min-width: 992px)',
        extraLarge: '(min-width: 1200px)'
    }
};

export { commonTheme };

